import { providers } from "ethers"
import {
  Anchor,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Footer,
  Grid,
  Grommet,
  Header,
  Heading,
  Image,
  Layer,
  Main,
  Menu,
  Meter,
  NameValueList,
  NameValuePair,
  Paragraph,
  ResponsiveContext,
  Text,
  ThemeContext,
} from "grommet"
import { CaretDownFill, Download, Link, Menu as MenuIcon } from "grommet-icons"
import { sortBy } from "lodash-es"
import { Fragment, useContext, useState } from "react"
import ReactPlayer from "react-player"
import hero from "./hero-background.jpg"
import theme, { navigationBarTheme } from "./theme"
import warpSoundLogo from "./warp-sound-logo.png"
import wvrpsLogo from "./wvrps-logo.png"

const MUSIC_TRAIT_PREFIX = "Music - "
const MUSIC_MODE_TRAIT_NAMES = [
  "Joy",
  "Dark",
  "Fresh",
  "Esoteric",
  "Light",
  "Weird",
  "Grime",
  "Slime",
  "Robotic",
].map((mode) => `${MUSIC_TRAIT_PREFIX}${mode}`)
const INSTRUMENT_TRAIT_NAMES = ["Lead", "Pad", "Bass", "Percussion"].map(
  (mode) => `${MUSIC_TRAIT_PREFIX}${mode}`,
)

const BPM_TRAIT_NAME = "BPM"
const SONG_KEY_TRAIT_NAME = "Music - Song Key"

/**
 * Changes a pinata ipfs url to use our own domain instead until we update that in the metadata
 */
const repointPinataUrl = (pinataUrl: string) =>
  pinataUrl.replace("wvrps.mypinata.cloud", "cdn.warpsound.ai")

type WvrpCardProps = {
  wvrp: any
}

function WvrpCard({ wvrp }: WvrpCardProps) {
  const pageWidth = useContext(ResponsiveContext)
  const [showStemsDownloadInstructions, setShowStemsDownloadInstructions] = useState(false)
  const traits = wvrp.traits as any[]

  const removeMusicTraitNamePrefix = (traitType: string) =>
    traitType.replace(MUSIC_TRAIT_PREFIX, "")

  const musicModeTraits = sortBy(
    wvrp.traits.filter(
      (trait: any) => MUSIC_MODE_TRAIT_NAMES.includes(trait.trait_type) && trait.value > 0,
    ),
    ["trait_type"],
  )

  const instrumentTraits = sortBy(
    wvrp.traits.filter((trait: any) => INSTRUMENT_TRAIT_NAMES.includes(trait.trait_type)),
    ["trait_type"],
  )

  const bpm = traits.find(({ trait_type }) => trait_type === BPM_TRAIT_NAME)
  const songKey = traits.find(({ trait_type }) => trait_type === SONG_KEY_TRAIT_NAME)
  const includedMusicalTraits = [...musicModeTraits, ...instrumentTraits, bpm, songKey]

  const visualTraits: any[] = sortBy(
    traits.filter(
      (trait) =>
        !includedMusicalTraits.includes(trait) && !trait.trait_type.includes(MUSIC_TRAIT_PREFIX),
    ),
    ["trait_type"],
  )

  return (
    <Fragment>
      {showStemsDownloadInstructions && (
        <Layer
          modal
          onEsc={() => setShowStemsDownloadInstructions(false)}
          onClickOutside={() => setShowStemsDownloadInstructions(false)}
        >
          <Box direction="column" justify="between" align="center" pad="large" gap="medium">
            <Box direction="column">
              <Heading
                level="3"
                margin="0"
                textAlign="center"
                style={{ fontFamily: "Hanson Bold", textTransform: "uppercase" }}
              >
                Download Stems
              </Heading>
              <Paragraph>
                You can request the stems of your WVRP's audio (the musical layers) in our Discord!
                Please click below to be redirected in order to
                <ol>
                  <li>Verify your wallet with the Discord server's collab.land bot</li>
                  <li>Request your stems once verified.</li>
                </ol>
              </Paragraph>
              <Box direction="row" fill="horizontal" justify="center">
                <Button
                  primary
                  label="Go to our Discord"
                  onClick={() =>
                    window.open(
                      "https://discord.com/channels/705518914657976361/933847039152914434",
                    )
                  }
                />
              </Box>
            </Box>
            <Button label="close" onClick={() => setShowStemsDownloadInstructions(false)} />
          </Box>
        </Layer>
      )}
      <Card background="light-1" elevation="medium">
        <CardHeader pad={{ horizontal: "small", top: "small" }} justify="between">
          <Heading level="4" margin="0" style={{ fontFamily: "Hanson Bold" }}>
            {wvrp.name.slice("WVRP ".length)}
          </Heading>
          <Box direction="row" align="center">
            <Menu
              icon={<Link color="accent-2" />}
              dropProps={{ align: { top: "bottom", right: "right" } }}
              items={[
                {
                  label: "OpenSea",
                  onClick: () =>
                    window.open(
                      `https://opensea.io/assets/0xcbc67ea382f8a006d46eeeb7255876beb7d7f14d/${wvrp.token_id}`,
                    ),
                },
                {
                  label: "LooksRare",
                  onClick: () =>
                    window.open(
                      `https://looksrare.org/collections/0xcBC67Ea382F8a006d46EEEb7255876BeB7d7f14d/${wvrp.token_id}`,
                    ),
                },
                {
                  label: "〰〰〰〰〰〰",
                  href: "#",
                },
                {
                  label: "Rara Avis",
                  onClick: () => window.open("https://www.raraavis.app/wvrps-tokens"),
                },
                {
                  label: "Rarity Sniper",
                  onClick: () =>
                    window.open(`https://raritysniper.com/wvrps-by-warpsound/${wvrp.token_id}`),
                },
                {
                  label: "Rarity Tools",
                  onClick: () =>
                    window.open(`https://rarity.tools/wvrps-by-warpsound/view/${wvrp.token_id}`),
                },
              ]}
            />
            <Menu
              icon={<Download color="accent-2" />}
              dropProps={{ align: { top: "bottom", right: "right" } }}
              items={[
                {
                  label: "Music",
                  href: repointPinataUrl(wvrp.external_link),
                },
                {
                  label: "Image",
                  href: repointPinataUrl(wvrp.image_original_url),
                },
                {
                  label: "Hi-Res Image",
                  href:
                    `https://cdn.warpsound.ai/ipfs/` +
                    `Qmema18kfpysTDvbm2zyhuoVMZ9i9CE2pHWn7zeQhA9j7y/` +
                    `WVRP-${wvrp.token_id}.png`,
                },
                {
                  label: "Stems",
                  onClick: () => setShowStemsDownloadInstructions(true),
                },
                {
                  label: "PFP + Music Video",
                  href: `https://cdn.warpsound.ai/wvrps-combination-videos/WVRP-${wvrp.token_id}.mp4`,
                },
              ]}
            />
          </Box>
        </CardHeader>
        <CardBody pad="small" align="start">
          <Box direction="column" fill="horizontal">
            <Box direction="column" flex="grow">
              <Box direction="row">
                <Image width="100%" height="auto" src={repointPinataUrl(wvrp.image_original_url)} />
              </Box>
              {/* Music */}
              <Box direction="column" flex="grow">
                <Box direction="row" justify="between">
                  <ReactPlayer
                    controls
                    width="100%"
                    height="50px"
                    url={repointPinataUrl(wvrp.external_link)}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              direction="column"
              height={pageWidth !== "small" ? "300px" : "auto"}
              pad={{ horizontal: "20px" }}
              overflow="auto"
            >
              {/* Traits */}
              <Box direction="column" flex="grow" gap="large" pad={{ vertical: "small" }}>
                <Box direction="column">
                  <Box direction="row" pad={{ bottom: "small" }}>
                    <Heading
                      level="3"
                      margin="0"
                      style={{ fontFamily: "Hanson Bold", textTransform: "uppercase" }}
                    >
                      Musical Traits
                    </Heading>
                  </Box>
                  <Box direction="row">
                    <NameValueList nameProps={{ width: "small" }}>
                      {musicModeTraits.map((trait: any) => (
                        <NameValuePair
                          key={trait.trait_type}
                          name={removeMusicTraitNamePrefix(trait.trait_type)}
                        >
                          <Box fill direction="row" align="center">
                            <Meter
                              values={[{ value: trait.value }]}
                              thickness="small"
                              size="small"
                              max={4}
                            />
                          </Box>
                        </NameValuePair>
                      ))}
                      {[...instrumentTraits, bpm, songKey].map((trait) => (
                        <NameValuePair
                          key={trait.trait_type}
                          name={removeMusicTraitNamePrefix(trait.trait_type)}
                        >
                          {trait.value}
                        </NameValuePair>
                      ))}
                    </NameValueList>
                  </Box>
                </Box>

                <Box direction="column">
                  <Box direction="row" pad={{ bottom: "small" }}>
                    <Heading
                      level="3"
                      margin="0"
                      style={{ fontFamily: "Hanson Bold", textTransform: "uppercase" }}
                    >
                      Visual Traits
                    </Heading>
                  </Box>
                  <Box direction="row">
                    <NameValueList nameProps={{ width: "small" }}>
                      {visualTraits.map((trait) => (
                        <NameValuePair key={trait.trait_type} name={trait.trait_type}>
                          {trait.value}
                        </NameValuePair>
                      ))}
                    </NameValueList>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardBody>
      </Card>
    </Fragment>
  )
}

type ConnectedViewProps = {
  wvrps: Array<any>
}

function ConnectedView(props: ConnectedViewProps) {
  const pageWidth = useContext(ResponsiveContext)
  return props.wvrps.length === 0 ? (
    <Box fill="horizontal">
      <Text textAlign="center">Your connected wallet doesn't seem to have any WVRPS in it</Text>
    </Box>
  ) : (
    <Grid
      fill="horizontal"
      columns={{ small: ["100%"], medium: "360px", large: "360px" }[pageWidth] || "100%"}
      gap="40px"
    >
      {props.wvrps.map((wvrp) => (
        <WvrpCard key={wvrp.id} wvrp={wvrp} />
      ))}
    </Grid>
  )
}

function HeroBanner() {
  const pageWidth = useContext(ResponsiveContext)
  return (
    <Box
      fill="horizontal"
      flex="grow"
      background={`url(${hero})`}
      height={pageWidth !== "small" ? "400px" : "250px"}
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        flexShrink: 0,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <img alt="" src={wvrpsLogo} width="50%" style={{ maxWidth: 800 }} />
        <div
          style={{
            paddingTop: pageWidth !== "small" ? 80 : 20,
            fontFamily: "Hanson Bold",
            fontSize: "3.5vw",
            color: "#F7F7F7",
            textTransform: "uppercase",
          }}
        >
          The Hub
        </div>
      </Box>
    </Box>
  )
}

const WVRPS_MENU_ITEMS = [
  { label: "WVRPS NFTs", href: "https://www.warpsound.ai/wvrps" },
  {
    label: "WarpSynth",
    href: "https://warpsynth.warpsound.ai/",
  },
  {
    label: "The Lab",
    href: "https://www.warpsound.ai/the-lab",
  },
  {
    label: "The Hub",
    href: "https://hub.warpsound.ai/",
  },
  { label: "〰〰〰〰〰〰〰〰〰", href: "#" },
  {
    label: "About",
    href: "https://mirror.xyz/warpsound.eth",
  },
  {
    label: "Team",
    href: "https://www.warpsound.ai/team",
  },
  {
    label: "Roadmap 1.0",
    href: "https://www.warpsound.ai/roadmap",
  },
  { label: "〰〰〰〰〰〰〰〰〰", href: "#" },
  {
    label: "FAQ",
    href: "https://www.warpsound.ai/wvrps-faq",
  },
  {
    label: "NFT Basics",
    href: "https://www.warpsound.ai/nft-basics",
  },
  {
    label: "Discord",
    href: "https://discord.gg/warpsound",
  },
  {
    label: "Marketplace",
    onClick: "https://marketplace.warpsound.ai/",
  },
]

function App() {
  const [wvrps, setWvrps] = useState<Array<any>>()
  const connectWallet = () => {
    ;(async () => {
      try {
        const provider = new providers.Web3Provider((window as any).ethereum)
        await provider.send("eth_requestAccounts", [])
        const signer = provider.getSigner()
        const accountAddress = await signer.getAddress()

        try {
          const { wvrps } = await fetch(
            `/.netlify/functions/get-owned-wvrps?owner-address=${accountAddress}`,
          ).then((response) => response.json())

          setWvrps(wvrps)
        } catch (error) {
          alert("Unable to get your WVRPS. Please try again later or let us know in Discord.")
          console.error(error)
        }
      } catch (error) {
        console.error(error)
        alert("Couldn't connect to your wallet")
      }
    })()
  }

  return (
    <Grommet full theme={theme}>
      <Header fill="horizontal" height="94px" background={"#1C1C1C"} pad={{ horizontal: "3vw" }}>
        <ThemeContext.Extend value={navigationBarTheme}>
          <ResponsiveContext.Consumer>
            {(pageWidth) => (
              <Box fill direction="row" justify="between">
                <Anchor
                  label={
                    <Image src={warpSoundLogo} width={pageWidth !== "small" ? "84px" : "84px"} />
                  }
                  href="https://www.warpsound.ai"
                  alignSelf="center"
                />
                {pageWidth !== "small" ? (
                  <Box fill direction="row" align="center">
                    <Box pad={{ left: "medium", right: "medium" }}>
                      <div style={{ height: 18, width: 2, backgroundColor: "#EEEEEE" }} />
                    </Box>
                    <Button
                      plain
                      label="ARTISTS"
                      onClick={() => window.open("https://www.warpsound.ai/artists")}
                      style={{ fontFamily: "Hanson Bold", fontSize: "18px" }}
                    />
                    <Box pad={{ left: "medium" }}>
                      <div style={{ height: 18, width: 2, backgroundColor: "#EEEEEE" }} />
                    </Box>
                    <Menu
                      dropProps={{ align: { top: "bottom", left: "left" }, elevation: "none" }}
                      icon={false}
                      style={{ fontFamily: "Hanson Bold" }}
                      color="text"
                      label={
                        <Box
                          direction="row"
                          align="center"
                          style={{ lineHeight: "normal", textTransform: "uppercase" }}
                        >
                          WVRPS <CaretDownFill />
                        </Box>
                      }
                      items={WVRPS_MENU_ITEMS}
                    />
                  </Box>
                ) : (
                  <Fragment>
                    <Box fill width="100%" />
                    <Menu
                      label={<MenuIcon />}
                      icon={false}
                      dropProps={{ align: { top: "bottom", right: "right" }, elevation: "none" }}
                      items={[
                        {
                          label: "ARTISTS",
                          href: "https://www.warpsound.ai/artists",
                        },
                        { label: "〰〰〰〰〰〰〰〰〰", href: "#" },
                        ...WVRPS_MENU_ITEMS,
                      ]}
                    />
                  </Fragment>
                )}
              </Box>
            )}
          </ResponsiveContext.Consumer>
        </ThemeContext.Extend>
      </Header>
      <Main direction="column" fill="horizontal" align="center">
        <HeroBanner />

        <Box direction="column" align="center" fill pad={{ vertical: "40px", horizontal: "20px" }}>
          {!wvrps ? (
            <Box
              direction="column"
              align="center"
              pad={{ top: "medium" }}
              style={{ maxWidth: 800 }}
            >
              <Paragraph fill textAlign="center">
                Welcome to the WVRPS HUB! Connect your wallet to display your WVRPS, their traits,
                their download options, and links to the marketplaces and rarity sites
              </Paragraph>
              <Box direction="row">
                <Button primary onClick={() => connectWallet()} label="Connect your wallet" />
              </Box>
            </Box>
          ) : (
            <ConnectedView wvrps={wvrps} />
          )}
        </Box>
      </Main>
      <Footer
        direction="column"
        align="center"
        justify="center"
        fill="horizontal"
        pad={{ top: "100px", bottom: "40px" }}
      >
        <Box direction="row" wrap justify="center" style={{ textAlign: "center" }}>
          &#169; 2023 AUTHENTIC ARTISTS. ALL RIGHTS RESERVED.
          <Box style={{ display: "inline-block" }}>
            ☆{" "}
            <a target="_blank" rel="noreferrer" href="http://warpsound.ai/terms">
              TERMS & CONDITIONS
            </a>{" "}
            ☆{" "}
            <a target="_blank" rel="noreferrer" href="https://www.warpsound.ai/privacy-policy">
              PRIVACY POLICY
            </a>
          </Box>
        </Box>
        <Box
          fill="horizontal"
          pad={{ horizontal: "20px" }}
          align="center"
          style={{ maxWidth: 800, fontSize: 12 }}
        >
          DISCLAIMER: WVRPS NFTS ARE DIGITAL COLLECTIBLES. NOTHING ON THIS SITE OR IN ANY WARPSOUND,
          WVRPS OR AUTHENTIC ARTISTS COMMUNICATIONS ARE TO BE CONSIDERED AS FINANCIAL ADVICE.
          POTENTIAL COLLECTORS NEED TO DO THEIR OWN RESEARCH AND EDUCATE THEMSELVES ON + UNDERSTAND
          THE RISKS INVOLVED WITH CRYPTOCURRENCIES AND NFTS AT LARGE.
        </Box>
      </Footer>
    </Grommet>
  )
}

export default App
