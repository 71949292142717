import { ThemeType } from "grommet"

const theme: ThemeType = {
  global: {
    colors: {
      brand: {
        dark: "#ff59c8",
        light: "#ff59c8",
      },
      "accent-1": {
        dark: "#72E2E6",
        light: "#72E2E6",
      },
      "accent-2": {
        dark: "#AB45E6",
        light: "#AB45E6",
      },
      "accent-3": {
        dark: "#C8E645",
        light: "#C8E645",
      },
      background: {
        dark: "#111111",
        light: "#FFFFFF",
      },
      "background-back": {
        dark: "#111111",
        light: "#EEEEEE",
      },
      "background-front": {
        dark: "#222222",
        light: "#FFFFFF",
      },
      "background-contrast": {
        dark: "#FFFFFF11",
        light: "#11111111",
      },
      text: {
        dark: "#EEEEEE",
        light: "#333333",
      },
      "text-strong": {
        dark: "#FFFFFF",
        light: "#000000",
      },
      "text-weak": {
        dark: "#CCCCCC",
        light: "#444444",
      },
      "text-xweak": {
        dark: "#999999",
        light: "#666666",
      },
      border: {
        dark: "#CCCCCC",
        light: "#444444",
      },
      focus: {
        dark: "#72E2E6",
        light: "#72E2E6",
      },
      control: "brand",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning",
    },
    font: {
      family: "Matter",
    },
    active: {
      background: "active-background",
      color: "active-text",
    },
    hover: {
      background: "active-background",
      color: "active-text",
    },
    selected: {
      background: "selected-background",
      color: "selected-text",
    },
  },
  // Fix the descender height that gets added to svg icons since they're inline-block
  // by default.
  icon: {
    extend: `
      display: block;
    `,
  },
  card: {
    body: {
      pad: "large",
    },
    header: {
      pad: {
        top: "small",
      },
    },
  },
  button: {
    // Must be defined for other button styles to take effect
    default: {
      extend: `
        line-height: normal;
      `,
    },
    primary: {
      background: "#ff59c8",
      color: "white",
      border: false,
      padding: { vertical: "20px", horizontal: "40px" },
      extend: `
        font-family: "Hanson Bold";
        border: none;
        font-size: 0.9rem;
        text-transform: uppercase;
        border-radius: 0.4rem;
        outline: none;
        line-height: normal;
      `,
    },
    hover: {
      primary: {
        extend: `
          border: none;
          opacity: 0.8;
          box-shadow: none;
        `,
      },
    },
    transition: {
      properties: ["opacity"],
      duration: 0.2,
      timing: "ease-in-out",
    },
  },
  nameValueList: {
    gap: {
      column: "0",
      row: "small",
    },
  },
}

export const navigationBarTheme: ThemeType = {
  menu: {
    extend: `
      font-family: "Hanson Bold";

      & a > div, & button > div {
        padding: 0px 20px;
        text-transform: uppercase;
      }

      & a:hover, & button:hover {
        color: ${(theme.global?.colors?.["brand"] as any)?.["dark"]};
        background: none;
      }
    `,
  },
}

export default theme
